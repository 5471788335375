<template>

<app-content :loading="is.loading && !is.initialised">

	<app-content-head :tabs="tabs" title="Discover" subtitle="Find opponents and events that match your particular interests." />

	<app-content-body :is-call="true" v-if="!collection.length">

		<app-content-call v-if="is.failed" icon="closed" title="Proposals closed" :text="$settings.schedule.events.submit.content" />

		<app-content-call v-if="!is.failed && !hasInterests" icon="matchmaking" title="What games do you want to play?" text="Add your interests, as general or specific as you want, so we can tailor your suggestions." button="Add interests" v-on:click="onAddClick" />

		<app-content-call v-if="!is.failed && !collection.length && hasInterests" icon="interests" title="No proposals currently" text="Proposals are events suggested to you by other attendees that you might be interested in. You could try adding more interests or broadening existing interests to attract more proposals." button="My interests" v-on:click="onInterestsClick" />

	</app-content-body>

	<app-events v-if="sent.length" :no-grow="true" title="Proposals sent" v-on:hide="load" :no-success="true" :loading="is.loading && is.initialised" :filter="filter" :collection="sent" :columns="columns" :pagination="pagination" :total="sent.length" placeholder="Sorry, no proposals sent." />
	
	<app-events v-if="received.length" :no-grow="true" title="Proposals received" v-on:hide="load" :no-success="true" :loading="is.loading && is.initialised" :filter="filter" :collection="received" :columns="columns" :pagination="pagination" :total="received.length" placeholder="Sorry, no proposals received." />

</app-content>

</template>

<script>

import mixCollection from '@/mixin/collection'

export default {

	mixins: [mixCollection],

	data: function() {

		return {
			endpoint: 'convention/schedule/discover/proposals',
			live: 'convention/schedule/discover/proposals',
			references: {
				times: [],
				days: [],
				lobbies: []
			},
			columns: {
				game: 'auto',
				seats: 100,
				date: 200,
				experience: 100,
				duration: 100
			}
		}

	},

	computed: {

		sent: function() {

			return this.$_.filter(this.collection, function(item) {

				return item.session.is.hosting

			})

		},

		received: function() {

			return this.$_.filter(this.collection, function(item) {

				return !item.session.is.hosting

			})

		},

		hasInterests: function() {

			return this.$store.getters['session/count/schedule/interests']

		},

		tabs: function() {

			return [
				{ name: 'Convention.Schedule.Discover', text: 'Suggestions' },
				{ name: 'Convention.Schedule.Discover.Matchmaker', text: 'Matchmaker' },
				{ name: 'Convention.Schedule.Discover.Proposals', text: 'Proposals' },
				{ name: 'Convention.Schedule.Discover.Interests', text: 'Interests' }
			]

		}

	},

	methods: {

		onInterestsClick: function() {

			this.$router.push({
				name: 'Convention.Schedule.Discover.Interests'
			})

		},

		onAddClick: function() {

			this.$router.push({
				name: 'Convention.Schedule.Discover.Interest',
				params: {
					id: 'new'
				}
			})

		}

	}

}

</script>

<style scoped>

</style>